import React from 'react'
import "./notfound.css"
const NotFound = () => {
  return (
    <div className='parent'>
      <div className="message">
        <h1>Error 404 not found</h1>
      </div>
    </div>
  )
}

export default NotFound