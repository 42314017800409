import React, { useEffect } from 'react';
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Course from "./components/Course/Course";
import NotFound from "./components/NotFound/NotFound";
import Book from "./components/Book/Book";
import Event from "./components/Event/Event";
import Service from "./components/Service/Service";
import Collaborators from "./components/Collaborators/Collaborators";
ReactGA.initialize("G-BM5XX3NF9C");
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
const App = () => {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-MDRX8P2Q",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
      <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="courses/:id" element={<Course />} />
        <Route path="play-books/:id" element={<Book />} />
        <Route path="events/:id" element={<Event />} />
        <Route path="services/:id" element={<Service />} />
        <Route path="collaborators/:id" element={<Collaborators />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
