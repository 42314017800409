import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import {
  get_slider,
  parse_event_URL,
  get_image,
  get_course_URL,
  get_book_URL,
} from "../../helper";
import Box from "../Box/Box";
import api from "../../axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import {
  Navigation,
  Autoplay,
  Pagination,
} from "swiper/modules";
import { FaTiktok, FaWhatsapp } from "react-icons/fa";
import { AiOutlineClose, AiFillYoutube, AiFillFacebook } from "react-icons/ai";
import { IoMdAlbums } from "react-icons/io";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
import "./event.css"
const Event = () => {
  const phoneNumber = "+201027097226";
  const whatsappURL = `https://wa.me/${phoneNumber}`;
  const [event, setEvent] = useState({});
  const [covers, setCovers] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [cards, setCards] = useState([]);
  const [setting, setSetting] = useState({});
  const [courses, setCourses] = useState([]);
  const [books, setBooks] = useState([])
  const [popupImages, setPopupImages] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [isSliderPopupOpen, setIsSliderPopupOpen] = useState(false);
  const { id } = useParams();
  const eventID = parse_event_URL(id);

  useEffect(() => {
    const token =
      "b64e49efc75f3e8fe6bd65fbd5536572230164c9ae29797fbbe4d21c2a5e721740d092a89c2ffa7a9e51e23e9db4674f5869ed4e5d7570afec99a2543a8eccbd4d433c440f4442e589ae0246941b26926a737a98b806425bcc0c4ec1bb0169c5a96155ff33f9368b09351b700970078991057c6b641ea05c6e9397849b87d6ce";

    const fetchBook = async () => {
      try {
        const response = await api.get(
          `https://api.sae-grad.com/api/events/${eventID}`,
          {
            params: { populate: "*" },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setEvent(response.data.data);
        setCovers(response?.data?.data?.attributes?.sliders?.data || []);
        setCards(response?.data?.data?.attributes?.gallery || []);
      } catch (error) {
        if (error.response) {
          console.log("Error data:", error.response.data);
          console.log("Error status:", error.response.status);
          console.log("Error headers:", error.response.headers);
          if (error.response.status === 403) {
            console.log(
              "Access forbidden: Check your API key and permissions."
            );
          }
        } else if (error.request) {
          console.log("Error request:", error.request);
        } else {
          console.log("Error message:", error.message);
        }
        setNotFound(true);
      }
    };
    api
      .get("/setting", {
        params: {
          populate: "*",
        },
      })
      .then((response) => {
        setSetting(response.data.data.attributes);
      });
    api.get("/courses").then((response) => {
      setCourses(response.data.data);
    });
    api
      .get("courses/", {
        params: {
          pagination: {
            pageSize: 50,
          },
          populate: [
            "instructors.image",
            "gallery.image",
            "gallery.images",
            "home_image",
            "sliders",
            "video",
            "pdf_file",
          ],
          locale: "en",
        },
      })
      .then((response) => {
        setCovers(response?.data?.data?.attributes?.slider?.data || []);
        console.log(response?.data?.data?.attributes);
      })
      .catch((error) => {
        setNotFound(true);
        console.log(error);
      });
      api.get("/play-books").then((response) => {
        setBooks(response.data.data);
      });
    fetchBook();
  }, [eventID]);

  if (notFound) return <NotFound />;
  if (!event?.id) return "Loading...";
  const handleImageClick = (images, index) => {
    setPopupImages(images?.data || []);
    setSliderIndex(index);
    setIsSliderPopupOpen(true);
  };
  const formatDescription = (text) => {
    return text.replace(/\n/g, '<br />');
  };
  // Function to check if the text is Arabic
  const isArabic = (text) => {
    const arabicPattern = /[\u0600-\u06FF]/;
    return arabicPattern.test(text);
  };

  // Determine the direction based on the description language
  const description = event?.attributes?.description|| "";
  const direction = isArabic(description) ? "rtl" : "ltr";
  return (
    <>
      <section className="interior">
        <div className="intro-image">
          <Swiper
            navigation={true}
            modules={[Navigation, Autoplay, Pagination]}
            className="mySwiper"
            autoplay={{ delay: 2000 }}
            pagination={{ clickable: true }}
            effect="coverflow"
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
          >
            {covers.map((cover, index) => (
              <SwiperSlide key={index}>
                <div className="intro-image-container">
                  <img
                    className="animated-coverflow"
                    loading="auto"
                    src={get_slider(cover)}
                    alt={`cover ${index + 1}`}
                    style={{
                      height:"auto",
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="section-container">
          <div className="text">
            <h1>{event?.attributes?.title}</h1>
            <p>
              <strong>{event?.attributes?.author}</strong>
            </p>
          </div>
          <div className="instructors" id="urban-mobile">
            <h2>Event Description</h2>
            <div
              className="instructor-info" id="event"
            >
              <p id="eventP"
              style={{direction}}
                dangerouslySetInnerHTML={{
                  __html: formatDescription(event?.attributes?.description),
                }}
              />
              <Box
            textContent={{
              discount: event?.attributes?.discount || "",
            }}
            buttonHref={event?.attributes?.form_url || "#"}
            whatsapp_link={setting?.whatsapp_link}
            confirmationMessage={event?.attributes?.confirmation_message} 
          />
            </div>
          </div>
          {cards.length > 0 && (
            <div className="Gallery">
              <div className="text">
                <h2>Gallery</h2>
              </div>
              <div className="gallery">
                <div className="gallery-container">
                  {cards.map((card, index) => (
                    <div
                      className="gallery-card"
                      key={index}
                      onClick={() => handleImageClick(card.images, index)}
                    >
                      <div className="image">
                        <img src={get_image(card.image)} alt="gallery" />
                        <div>
                          <IoMdAlbums className="album" />
                        </div>
                      </div>
                      <div className="card-text">
                        <div className="heading">
                          <h1>{card.name}</h1>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {isSliderPopupOpen && (
                  <div className="slider-popup">
                    <div className="slider-popup-content">
                      <div
                        className="slider-close-icon"
                        onClick={() => setIsSliderPopupOpen(false)}
                      >
                        <AiOutlineClose />
                      </div>
                      <Swiper
                        navigation={true}
                        modules={[Navigation, Pagination]}
                        initialSlide={sliderIndex}
                        className="slider-popup-swiper"
                        pagination={{ clickable: true }}
                      >
                        {popupImages.map((image, index) => (
                          <SwiperSlide key={index}>
                            <div className="slider-popup-image">
                              <img src={get_slider(image)} alt="popup" />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          
          <div className="footer">
          <div className="social">
              <h3>Contact Us</h3>
              <a href={whatsappURL} target="_blank" rel="noopener noreferrer">
                <FaWhatsapp /> {phoneNumber}
              </a>
              <a href={setting?.facebook_link} target="_blank" rel="noreferrer">
                <AiFillFacebook className="icon" /> Facebook
              </a>
              <a
                href={setting?.instagram_link}
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="icon" /> Instagram
              </a>
              <a
                href="mailto:info@sae-grad.com"
                target="_blank"
                rel="noreferrer"
              >
                <CiMail className="icon" /> Mail
              </a>
              <a href={setting?.tiktok_link} target="_blank" rel="noreferrer">
                <FaTiktok className="icon" /> TikTok
              </a>
              <a href={setting?.linkedin_link} target="_blank" rel="noreferrer">
                <BsLinkedin className="icon" /> LinkedIn
              </a>
              <a href={setting?.youtube_link} target="_blank" rel="noreferrer">
                <AiFillYoutube className="icon" /> Youtube
              </a>
            </div>
            <div className="course">
              <h3>Other Programs</h3>
              {courses.map((course) => (
                <Link to={"/courses/" + get_course_URL(course)}>
                  {course?.attributes?.title}
                </Link>
              ))}
            </div>
            <div className="course">
              <h3>Other Playbooks</h3>
              {books.map((book) => (
                <Link to={"/play-books/" + get_book_URL(book)}>
                  {book?.attributes?.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Event;
