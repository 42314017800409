import React, {useState} from 'react'
import "./register.css"
import { RiCloseLine } from "react-icons/ri";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { IoLogoGoogleplus } from "react-icons/io";
const Register = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [activeForm, setActiveForm] = useState("");
  const handleOpenPopup = (formType) => {
    setActiveForm(formType);
    setPopupVisible(true);
  };
  const handleClosePopup = () => {
    setPopupVisible(false);
  };
  function LoginForm() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };
    const handleFormSubmit = async (e) => {
      e.preventDefault();

      
    };
    return (
      <form onSubmit={handleFormSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
          required
        />
        <div className="forgot-password">
          <a href="#">Forgot password?</a>
        </div>
        <div className="submit">
          <button type="submit">Log In</button>
        </div>
      </form>
    );
  }
  function SignupForm() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleNameChange = (e) => {
      setName(e.target.value);
    };
    const handleEmailChange = (e) => {
      setEmail(e.target.value);
    };
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };
    const handleFormSubmit = async (e) => {
      e.preventDefault();

      
    };


    return (
      <form onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={handleNameChange}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
          required
        />
        <div className="submit">
          <button type="submit">Sign Up</button>
        </div>
      </form>
    );
  }
  return (
            <div className="btns">
                <button onClick={() => handleOpenPopup("login")}>Log in</button>
                |
                <button onClick={() => handleOpenPopup("signup")}>
                  Sign Up
                </button>
                {isPopupVisible && (
                  <div className="register-popup scale-up-center">
                    <div className="popup-content">
                      <div className="close">
                        <button
                          className="close-icon"
                          onClick={handleClosePopup}
                        >
                          <RiCloseLine />
                        </button>
                      </div>
                      <div className="form-container">
                        <div className="buttons">
                          <button
                            className={`form-button ${
                              activeForm === "login" ? "active" : ""
                            }`}
                            onClick={() => setActiveForm("login")}
                          >
                            Log In
                          </button>
                          <button
                            className={`form-button ${
                              activeForm === "signup" ? "active" : ""
                            }`}
                            onClick={() => setActiveForm("signup")}
                          >
                            Sign Up
                          </button>
                        </div>
                        <div className="form-icons">
                          <FaFacebookF size={30} />
                          <FaLinkedinIn size={30} />
                          <IoLogoGoogleplus size={35} />
                        </div>
                        {activeForm === "login" && <LoginForm />}
                        {activeForm === "signup" && <SignupForm />}
                      </div>
                    </div>
                  </div>
                )}
              </div>
  )
}

export default Register