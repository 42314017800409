export const get_image = (object) => {
    const imageURL = object?.data?.attributes?.url;
    return "https://api.sae-grad.com" + imageURL;
}
export const get_slider = (object) => {
    const imageURL = object?.attributes?.url;
    return "https://api.sae-grad.com" + imageURL;
}
export const get_course_URL = (course) => 
course?.attributes?.title.replaceAll(" ", "-") + "-" +course?.id; 
export const get_book_URL = (book) => 
    book?.attributes?.title.replaceAll(" ", "-") + "-" +book?.id; 
export const get_event_URL = (event) => 
    event?.attributes?.title.replaceAll(" ", "-") + "-" +event?.id; 
export const get_service_URL = (service) => 
    service?.attributes?.title.replaceAll(" ", "-") + "-" +service?.id; 
export const get_collab_URL = (collab) => 
    collab?.attributes?.title.replaceAll(" ", "-") + "-" +collab?.id; 
export const parse_course_URL = (url) => {
    const parse = url.split("-");
    return parse[parse.length-1];
}
export const parse_book_URL = (url) => {
    const parse = url.split("-");
    return parse[parse.length-1];
}
export const parse_event_URL = (url) => {
    const parse = url.split("-");
    return parse[parse.length-1];
}
export const parse_service_URL = (url) => {
    const parse = url.split("-");
    return parse[parse.length-1];
}
export const parse_collab_URL = (url) => {
    const parse = url.split("-");
    return parse[parse.length-1];
}