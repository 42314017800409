import React, { useState, useEffect } from "react";
import api from "../../axios";
import {
  get_course_URL,
  get_book_URL,
  get_image,
} from "../../helper";
import {useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "swiper/css/navigation";
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiFillYoutube,
} from "react-icons/ai";
import { FaTiktok, FaPaperclip, FaLinkedin } from "react-icons/fa";
import { CiMail } from "react-icons/ci";
import mouse from "../../assets/mouse.png"
import "./home.css";

const Home = () => {
  const phoneNumber = "+201027097226";
  const whatsappURL = `https://wa.me/${phoneNumber}`;
  const [courses, setCourses] = useState([]);
  const [books, setBooks] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [setting, setSetting] = useState({});
  const [services, setServices] = useState([]);
  const [events, setEvents] = useState([]);
  const [collab, setCollab] = useState([]);
  useEffect(() => {
    api
      .get("/setting", {
        params: {
          populate: "*",
        },
      })
      .then((response) => {
        setSetting(response.data.data.attributes);
      });
    api.get("/faqs").then((response) => {
      setFaqs(response.data.data);
    });
    api
      .get("/courses", {
        params: {
          pagination: {
            pageSize: 50,
          },
          populate: [
            "instructors.image",
            "gallery.image",
            "gallery.images",
            "home_image",
            "sliders",
          ],
          locale: "en",
        },
      })
      .then((response) => {
        setCourses(response.data.data);
      });
    api
      .get("/services", {
        params: {
          populate: "*",
        },
      })
      .then((response) => {
        setServices(response.data.data);
      });
    api
      .get("/play-books", {
        params: {
          populate: "*",
        },
      })
      .then((response) => {
        setBooks(response.data.data);
      });
    api
      .get("/events", {
        params: {
          populate: "*",
        },
      })
      .then((response) => {
        setEvents(response.data.data);
      });
    api
      .get("/collaborators", {
        params: {
          populate: "*",
        },
      })
      .then((response) => {
        setCollab(response.data.data);
      });
  }, []);
  const navigate = useNavigate();
  const handleCourseClick = (course) => {
    navigate("courses/" + get_course_URL(course));
  };
  const handleBookClick = (book) => {
    navigate("play-books/" + get_book_URL(book));
  };
  const handleEventClick = (event) => {
    navigate("events/" + get_book_URL(event));
  };
  const handleServiceClick = (service) => {
    navigate("services/" + get_book_URL(service));
  };
  const handleCollabClick = (collab) => {
    navigate("collaborators/" + get_book_URL(collab));
  };
  // const [message, setMessage] = useState("");
  const [placeholder, setPlaceholder] = useState("Attach files");

  const handleFileChange = (event) => {
    const fileInput = event.target;
    const fileName = fileInput.files[0].name;
    setPlaceholder(fileName);
  };
  return (
    <>
      <section className="home mouse">
        <div className="home-cover">
          <img src={get_image(setting.wallpaper_top)} alt="cover" />
        </div>
        <div className="home-container">
          
          <div id="programms">
            {courses.length > 0 && <h1 className="featured">Programs</h1>}
            <div className="courses">
              {courses.map((course, index) => (
                <div
                  key={index}
                  className="card"
                  onClick={() => handleCourseClick(course)}
                >
                  <img
                    src={get_image(course.attributes.home_image)}
                    alt={course.name}
                  />
                  <div className="card-text">
                    <h3>{course?.attributes?.title}</h3>
                    <p> {course?.attributes?.author}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div id="books">
            {books.length > 0 && <h1 className="featured">Playbooks</h1>}
            <div className="courses">
              {books.map((book, index) => (
                <div
                  key={index}
                  className="card"
                  onClick={() => handleBookClick(book)}
                >
                  <img
                    src={get_image(book.attributes.home_image)}
                    alt={book.name}
                  />
                  <div className="card-text">
                    <h3>{book?.attributes?.title}</h3>
                    <p> {book?.attributes?.author}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div id="services">
            {services.length > 0 && <h1 className="featured">Services</h1>}
            <div className="courses">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="card"
                onClick={() => handleServiceClick(service)}
                >
                  <img
                    src={get_image(service.attributes.home_image)}
                    alt={service.name}
                  />
                  <div className="card-text">
                    <h3>{service?.attributes?.title}</h3>
                    <p> {service?.attributes?.author}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div id="events">
            {events.length > 0 && <h1 className="featured">Events</h1>}
            <div className="courses">
              {events.map((event, index) => (
                <div
                  key={index}
                  className="card"
                 onClick={() => handleEventClick(event)}
                >
                  <img
                    src={get_image(event.attributes.home_image)}
                    alt={event.name}
                  />
                  <div className="card-text">
                    <h3>{event?.attributes?.title}</h3>
                    <p> {event?.attributes?.author}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div id="collaborators">
            {collab.length > 0 && <h1 className="featured">Collaborators</h1>}
            <div className="courses">
              {collab.map((colab, index) => (
                <div
                  key={index}
                  className="card"
                onClick={() => handleCollabClick(colab)}
                >
                  <img
                    src={get_image(colab.attributes.home_image)}
                    alt={colab.name}
                  />
                  <div className="card-text">
                    <h3>{colab?.attributes?.title}</h3>
                    <p> {colab?.attributes?.author}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="home-content">
            <div className="faq">
              <h1>Frequently Asked Questions - FAQ </h1>
              <div className="faq-content">
                {faqs.map((faq) => (
                  <Accordion
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{
                        padding: "0",
                        fontSize: "13px",
                      }}
                    >
                      <Typography style={{ fontFamily: "Lexend" }}>
                        {faq?.attributes?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ fontFamily: "Lexend" }}>
                        {faq?.attributes?.description}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </div>
            <div className="touch">
              <h1>Get In Touch</h1>
              <input
                type="text"
                placeholder="Write your message here" /*onChange={e => setMessage(e.target.value)} */
              />
              <div className="file">
                <div className="addFile">
                  <label htmlFor="file-input" className="file-input-label">
                    <FaPaperclip className="file-input-icon" />
                    {placeholder}
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf, .doc,.docx,.xml"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="btn">
                  <button /*onClick={handleSendMessage}*/>Send</button>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="social-icons">
              <a href={setting?.facebook_link} target="_blank" rel="noreferrer">
                <AiFillFacebook className="icon" size={35} />
              </a>
              <a
                href={setting?.instagram_link}
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineInstagram className="icon" size={35} />
              </a>
              <a href={setting?.tiktok_link} target="_blank" rel="noreferrer">
                <FaTiktok className="icon" size={30} />
              </a>
              <a href={setting?.linkedin_link} target="_blank" rel="noreferrer">
                <FaLinkedin className="icon" size={30} />
              </a>
              <a href={setting?.youtube_link} target="_blank" rel="noreferrer">
                <AiFillYoutube className="icon" size={35} />
              </a>
              <a
                href="mailto:info@sae-grad.com"
                target="_blank"
                rel="noreferrer"
              >
                <CiMail className="icon" size={35} />
              </a>
            </div>
          </div>
        </div>
        <div className="home-cover2">
          <img src={get_image(setting.wallpaper_bottom)} alt="cover2" />
        </div>
      </section>
    </>
  );
};
export default Home;
